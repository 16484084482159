import React from "react"
import { Seo } from "src/component/common/seo"
import Casestudies from "src/component/layout/casestudies"
import Button from "src/component/common/button"
import { StaticImage } from "gatsby-plugin-image"
import GeospatialDataCasestudyList from "src/component/list/geospatial-dataCasestudyList"

export default function GeospatialData() {
    return (
        <>
         <Casestudies>
          <div className="geospatial-data inner-content">
            <section className="introduction__mv">
                    <div className="introduction__mv__inner c-inner-primary">
                    <div className="introduction__mv__img">
                            <StaticImage
                              src="../../assets/img/pages/casestudies/casestudies_geospatial-data.png"
                              alt="株式会社DATAFLUCT（データフラクト）"
                            placeholder="blurred"
                            className="pc-show"
                            />
                            <StaticImage
                              src="../../assets/img/pages/casestudies/casestudies_geospatial-data-sp.png"
                              alt="株式会社DATAFLUCT（データフラクト）"
                            placeholder="blurred"
                            className="sp-show"
                            />
                    </div>

                        <div className="introduction__mv__content">
                                <h2 className="introduction__mv__content__title">
                                 <p>地理空間データ</p>
                                  <span>GEOSPATIAL DATA</span>
                                </h2>
                                <p className="introduction__mv__lead">
                                    位置情報・施設・行動ログ・気象情報をAirlakeで融合し、
                                    <br className="c-pc" />
                                    AIが高度な空間分析と未来予測を実施。
                                  <br />
                                    スマート都市計画や防災力強化、交通システムの最適化を支援し、
                                    <br className="c-pc" />
                                    地域社会をアップデートします。
                               </p>
                         </div>
        
                    </div>
                </section>
                



                <section className="introduction__content">
                    <div className="introduction__content__inner c-inner-primary">
                        <div className="introduction__content__title">
                            <h2 className="js-scroll">
                                <div><div><span>地理空間データを<br />重ね合わせ、都市の未来を<br className="c-sp" />鮮やかに描き出す</span></div></div>
                            </h2>
                        </div>
                        <div className="introduction__content__textwrap">
                            <ul>
                                <li className="introduction__content__text">
                                 <h3>AI解析で交通需要予測</h3>
                                 <p>
                                    位置・気象データをAIで統合解析し、公共交通機関の効率的な運行を支援。
                                 </p>
                                </li>
                                <li className="introduction__content__text">
                                 <h3>位置情報で地域価値向上</h3>
                                 <p>
                                   観光資源・訪問行動をAI解析し、地域資産の戦略的活用と価値向上策を提示。
                                 </p>
                                </li>
                                <li className="introduction__content__text">
                                 <h3>AIでスマートビル最適化</h3>
                                 <p>
                                    センサーや環境データをAI解析し、運用コスト削減と快適性を両立した運営を実現。
                                 </p>
                                </li>
                                <li className="introduction__content__text">
                                 <h3>空間IDで動線最適化</h3>
                                 <p>
                                    屋内空間の空間IDを解析し、施設内の動線改善と空間効率最大化を実現。
                                 </p>
                                </li>
                                <li className="introduction__content__text">
                                 <h3>AI立地分析で投資最適化</h3>
                                 <p>
                                    オルタナティブデータを融合解析し、不動産投資の収益性を最大化する立地を提案。
                                 </p>
                                </li>
                                <li className="introduction__content__text">
                                 <h3>BIMで施設管理高度化</h3>
                                 <p>
                                   BIMデータの精密解析により、建物の運用管理・維持管理の効率化を支援。
                                 </p>
                                </li>

                            </ul>
         
                        </div>
                    </div>
                </section>

                <section className="p-top-casestudy">
                    <div className="p-top-casestudy__inner c-inner-primary">
                        <div className="p-top-casestudy__title c-title01">
                            <h2 className="p-top-casestudy__title__text c-title01__text">導入事例</h2>
                        </div>
                        <GeospatialDataCasestudyList />
                    </div>
                </section>
            </div>
          </Casestudies>
        </>
    )
}

export function Head() {
    return (
        <Seo
            title="GEOSPATIAL DATA"
            description=""
            pathname=""
        />
    )
}